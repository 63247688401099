let liGood = `<li class="file-list-item good">
            <div class="file-list-item-name">{{name}}</div>
            
            <div class="file-list-item-select"> to 
                <select class="format-select" tabindex="-1" aria-hidden="true">
                <option>. . .</option>
                
                <optgroup label="Image">
                    <option value="JPG">JPG</option>
                    <option value="PNG">PNG</option>
                    <option value="JPEG">JPEG</option>
                    <option value="SVG">SVG</option>
                    <option value="PSD">PSD</option>
                    <option value="TIFF">TIFF</option>
                    <option value="BMP">BMP</option>
                    <option value="GIF">GIF</option>
                    <option value="RGB">RGB</option>
                    <option value="SGI">SGI</option>
                    <option value="HDR">HDR</option>
                    <option value="PDB">PDB</option>
                    <option value="WEBP">WEBP</option>
                </optgroup>
                <optgroup label="Document">
                    <option value="JPG">JPG</option>
                    <option value="PNG">PNG</option>
                    <option value="JPEG">JPEG</option>
                    <option value="SVG">SVG</option>
                    <option value="PSD">PSD</option>
                    <option value="TIFF">TIFF</option>
                </optgroup>
                <optgroup label="EBook">
                    <option value="JPG">JPG</option>
                    <option value="PNG">PNG</option>
                    <option value="JPEG">JPEG</option>
                </optgroup>
                
                <optgroup label="Presentation">
                    <option value="JPG">JPG</option>
                </optgroup>
                 </select>
            </div>            
            <div class="file-list-item-size">{{fileSize}}</div>
            <div class="file-list-item-label prepared">Prepared</div>
            <div class="file-list-item-delete"> <img src="../img/trash.svg"/></div>
            
        </li>`;
let liError = `<li class="file-list-item error">
                <div class="file-list-item-name">{{name}}</div>
                <div class="file-list-item-error">The file is too big! ({{fileSize}})</div>
                <div class="file-list-item-label error">Error</div>
                <div class="file-list-item-delete">
                    <img src="../img/trash.svg"/>
                </div>
            </li>`;
let idInterval1, idInterval2;
let arrayFiles = [];

function HeaderDrop(){
 $(document).on('click','.header-dropdown',function (){
     let svgElement = $(this).find('svg');
     $('.dropdown-menu').toggleClass('open');
     if ($('.dropdown-menu').hasClass('open')) {
         $('.header-dropdown').addClass('active');
         svgElement.css('transform', 'rotate(-180deg)');
     } else {
         $('.header-dropdown').removeClass('active');
         svgElement.css('transform', 'rotate(0deg)');
     }
     if(innerWidth <= 666){
         $('.header-auth').hide();
         $('.header-burger-icon').show();
         $('.header-close').hide();
     }
 })
    $(document).on('click','.header-search svg',function (){
        $('.input-search-main').show();
        $('.input-search-main').focus();
    })
    $(document).on('click','.header-burger-icon',function () {
        $('.header-auth').css('display','flex');
        $('body').addClass('no-scroll');
        $('.header-close').show();
        $(this).hide();
        $('.dropdown-menu').removeClass('open');
        $('.header-dropdown svg').css('transform', 'rotate(0deg)');
    })
    $(document).on('click','.header-close',function () {
        $('.header-auth').hide();
        $('.header-burger-icon').show();
        $('body').removeClass('no-scroll');
        $(this).hide();
        $('.dropdown-menu').removeClass('open');
        $('.header-dropdown svg').css('transform', 'rotate(0deg)');
    })
    $(document).on('mousedown', function (event) {
        if ($('.dropdown-menu').hasClass('open') && !$('.dropdown-menu').is(event.target) && $('.dropdown-menu').has(event.target).length === 0 && !$('.header-dropdown').is(event.target)) {
            $('.dropdown-menu').removeClass('open');
            $('.header-dropdown').removeClass('active');
            $('.header-dropdown svg').css('transform', 'rotate(0deg)');
        }
    });
}
function Accordion() {
    $(".set > a").on("click", function () {
        if ($(this).hasClass("active")) {
            $(this).removeClass("active");
            $(this).siblings(".content").slideUp(200);
        } else {
            $(".set > a").removeClass("active");
            $(this).addClass("active");
            $(".content").slideUp(200);
            $(this).siblings(".content").slideDown(200);
        }
    });
}

function TabsFaQ() {
    $(document).on('click', '.explore-tabs__name li', function () {
        let index = $(this).index();

        if(innerWidth > 666) {
            $('.explore-tabs__name li').removeClass('active');
            $(this).addClass('active');
        }
        $('.explore-tabs__text li').hide();
        $('.explore-tabs__text li').eq(index).css('display', 'flex');
        if(innerWidth <= 666) {
            $('.explore-tabs__name').hide();
            $('.explore-tabs__text').show();
            $('.back-btn').css('display', 'flex');
        }
    });
    $(document).on('click', '.back-btn', function () {
        $('.back-btn').hide();
        $('.explore-tabs__name').show();
        $('.explore-tabs__text').hide();
    })
}
function InitSlider(){
    if(innerWidth <= 666) {
        const swiper = new Swiper('.latest-blog-swiper', {
            slidesPerView: 'auto',
            spaceBetween: 20,
            speed: 300,
            watchOverflow: false,
            slidesPerGroup: 1,
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.latest-blog-swiper-button-next',
                prevEl: '.latest-blog-swiper-button-prev',
            },
        });
        const swiper1 = new Swiper('.account-plan-swiper', {
            slidesPerView: 'auto',
            spaceBetween: 20,
            speed: 300,
            watchOverflow: false,
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.latest-blog-swiper-button-next',
                prevEl: '.latest-blog-swiper-button-prev',
            },
        });

    }
}
function DropDrop(){

        $('.drop-area').on('dragover', function(e) {
            e.preventDefault();
            $(this).addClass('drag-over');
        });

        $('.drop-area').on('dragleave drop', function() {
            $(this).removeClass('drag-over');
        });

        $('.drop-area').on('drop', function(e) {
            var files = e.originalEvent.dataTransfer.files;
            e.preventDefault();

            CreateArrayFiles(files)
            SetAllValue();
            changeNumberProgress('2');
            handleFiles(files);
        });

        $('#fileInput').on('change', function() {
            var files = $(this)[0].files;
            CreateArrayFiles(files)
            SetAllValue();
            changeNumberProgress('2');
            handleFiles(files);
        });

        function formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return '0 Bytes';
            let k = 1024;
            let dm = decimals < 0 ? 0 : decimals;
            let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            let i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }

        function handleFiles(files) {
            let fileList = $('#file-list');

            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                let fileSize = formatBytes(file.size);
                let MB100 = 5242880;
                let firstName = liGood.replace('{{fileSize}}', fileSize);
                let finalSize = firstName.replace('{{name}}', file.name);

                if(file.size < MB100 ){
                    fileList.append(finalSize);

                    StartSelect();
                    SetAllValue();
                }
                else{
                    let firstName = liError.replace('{{fileSize}}', fileSize);
                    let finalError = firstName.replace('{{name}}', file.name);
                    fileList.append(finalError);
                }
                if(innerWidth <= 666)$('.our-achieve-wrapper').css('padding-top', (arrayFiles.length * 9) + 20 + 'rem')
                else $('.our-achieve-wrapper').css('padding-top', (arrayFiles.length * 6) + 19 + 'rem')
            }
        }
}
function SelectChooseFiles() {
    $(document).on('mousedown', '.select-files', function (event) {
        let dropdown = $(this).closest('.convertor-footer').find('.select-files-dropdown');
        dropdown.toggleClass('open');
        if (dropdown.hasClass('open')) {
            $(this).find('img:last-child').css('transform', 'rotate(180deg)');
        } else {
            $(this).find('img:last-child').css('transform', 'rotate(0deg)');
        }
    });

    $(document).on('mousedown', function (event) {
        if ($('.select-files-dropdown').hasClass('open') && !$('.select-files-dropdown, .select-files').is(event.target) && $('.select-files-dropdown, .select-files').has(event.target).length === 0) {
            $('.select-files-dropdown').removeClass('open');
            $('.select-files').find('img:last-child').css('transform', 'rotate(0deg)');
        }
    });

    $(document).on('mousedown', '.input-file-trigger, .select-files-dropdown', function (event) {
        event.stopPropagation();
    });
}
function StartSelect(){
    $('.format-select').select2({
        placeholder: "...",
        dropdownParent: $('.format-select-wrapp'),
    }).on('select2:opening', function (e) {
        $(this).data('select2').$dropdown.find(':input.select2-search__field').attr('placeholder', 'Search');
    });
    $('.format-select-all').select2({
        placeholder: "...",
        dropdownParent: $('.format-select-wrapp1'),
    }).on('select2:opening', function (e) {
        $(this).data('select2').$dropdown.find(':input.select2-search__field').attr('placeholder', 'Search');
    });

    $(document).on('click','.select2-results__group',function (){
        $('.select2-results__group').closest('li').find('ul').removeClass('show');
        $('.select2-results__group').closest('li').find('ul').addClass('hide');
        $(this).closest('li').find('ul').addClass('show');
        $(this).closest('li').find('ul').removeClass('hide');
    })
}
function StartThreeStep(){
    $(document).on('click','.btn-convert',CreateHtmlConvertionStep)
}
function CreateHtmlConvertionStep(){
    progressConvertion('100');
    changeNumberProgress('3');
    // ajax
    $('.file-list li.good').each(function (){
        let fileOne = $(this);
        fileOne.removeClass('good');
        fileOne.addClass('conversion');
        $('.file-list-item-label').removeClass('prepared');
        $('.file-list-item-label').addClass('conversion');
        $('.file-list-item-label').text('conversion');
        $('.file-list-item-size').hide();
        $('.file-list-item-select').hide();
        $('.file-list-item-delete').hide();
        fileOne.append(` <div class="file-list-item-cancel">Cancel</div>`);
    })
    if($('.file-list li.error').length > 0)location.pathname = '/account-plan.html';

}
function changeNumberProgress(num){
    switch(num) {
        case '1':
            $('.progress-bar > div').removeClass('active');
            $('.progress-bar > div').removeClass('complete');
            $('.circle-1').addClass('active');
            $('.drop-area').show();
            $('.convertor-footer2, .convert-all').removeClass('show');
            $('.convertor-footer').addClass('show');
            $('.format-select-all').val('Convert all to')
            break;
        case '2':
            $('.progress-bar > div').removeClass('active');
            $('.progress-bar > div').removeClass('complete');
            $('.circle-1').addClass('complete');
            $('.circle-2').addClass('active');
            $('.drop-area').hide();
            $('.convertor-footer, .progress-convertion').removeClass('show');
            $('.convertor-footer2, .convert-all').addClass('show');
            $('.file-list li.conversion').each(function (){
                let fileOne = $(this);
                fileOne.removeClass('conversion');
                fileOne.addClass('good');
                $('.file-list-item-size').show();
                $('.file-list-item-select').show();
                $('.file-list-item-delete').show();
                $('.file-list-item-cancel').remove();
            })
            $('.file-list-item-label').removeClass('conversion')
            $('.file-list-item-label').addClass('prepared')
            $('.file-list-item-label').text('prepared');
            if(innerWidth <= 666)$('.convertor-wrapper').animate({top: "4rem"}, 1000);
            break;
        case '3':
            $('.progress-bar > div').removeClass('active');
            $('.progress-bar > div').removeClass('complete');
            $('.circle-1, .circle-2').addClass('complete');
            $('.circle-3').addClass('active');
            $('.convert-all, .convertor-footer2, .convertor-footer').removeClass('show');
            $('.progress-convertion').addClass('show')
            if(innerWidth <= 666)$('.convertor-wrapper').animate({top: "-7rem"}, 1000);
            break;
    }
}
function CreateArrayFiles(files){
    for (var i = 0; i < files.length; i++) {
        arrayFiles.push(files[i]);
    }
}
function deleteFileItem(){
    $(document).on('click','.file-list-item-delete',function (){
        let num =  $(this).closest('li').index();
        arrayFiles.splice(num-1, 1);
        $(this).closest('li').remove();
        if(arrayFiles.length === 0){
            changeNumberProgress('1');
            if(innerWidth <= 666) $('.our-achieve-wrapper').css('padding-top', 10 + 'rem')
            else $('.our-achieve-wrapper').css('padding-top', 19 + 'rem')
        }
        else{
            if(innerWidth <= 666)$('.our-achieve-wrapper').css('padding-top', (arrayFiles.length * 9) + 20 + 'rem')
            else $('.our-achieve-wrapper').css('padding-top', (arrayFiles.length * 4) + 19 + 'rem')
        }
    })
}

function SetAllValue(){
    let value = $('.format-select-all').val();
    if(value !== 'Convert all to'){
        $('.format-select').each(function (){
            $(this).val(value).trigger('change');
        })
    }
}
function CloseBtnThreeStep() {
    $(document).on('click', '.file-list-item-cancel',ClearThreeStep)
}
function ClearThreeStep(){
    let elem = $(".progress-convertion-bar-side");
    var elem1 = $(".progress-convertion > p span");

    changeNumberProgress('2')
    clearInterval(idInterval1);
    clearInterval(idInterval2);
    elem.css("width", 0 + "%");
    elem1.html("00:30");
}
function TabsPlan(){
    $(document).on('click', '.account-plan-tab li', function () {
        let index = $(this).index();
        $('.account-plan-tab li').removeClass('active');
        $(this).addClass('active');
        $('.account-plan-hidden > li').hide();
        $('.account-plan-hidden > li').eq(index).css('display', 'flex');
    });
}

function changeInput(){
    $(document).on('focus', '#contact-form input, #contact-form textarea', function() {
        $(this).attr('placeholder','Start typing...');
        $(this).closest('.contact-form-input').find('.label').addClass('active')
        $(this).addClass('active')
    });
    $(document).on('change', '#contact-form input, #contact-form textarea', function() {
        $(this).closest('.contact-form-input').find('.contact-form-input-error').text('')
        $(this).removeClass('error');
        $('#contact-form .button-transparent').removeAttr('disabled')
    })
    $('.format-select-all').on('change',function (){
        SetAllValue();
    })
}
function submitContactForm(){
    let flag = 0;
    $('#contact-form').on('submit',function (e){
        e.preventDefault();
        flag = 0;
        $('#contact-form .button-transparent').removeAttr('disabled')

        $('#contact-form input').each(function (){
            if($(this).val()===''){
                $(this).closest('.contact-form-input').find('.contact-form-input-error').text('The field cannot be empty');
                $(this).addClass('error')
                flag = 1;
                $('#contact-form .button-transparent').attr('disabled','disabled')
            }
        })
        if($('textarea').val()===''){
            $('textarea').closest('.contact-form-input').find('.contact-form-input-error').text('The field cannot be empty')
            $('textarea').addClass('error')
            $('#contact-form .button-transparent').attr('disabled','disabled')
            flag = 1;
        }
        let form;
        if(flag === 0){
            form = $(this).serialize();
            $(this)[0].reset();
        }
    })
}
function progressConvertion(val){
    let widthSuccess = val;
    var i = 0;
    if (i == 0) {
        i = 1;
        var elem = $(".progress-convertion-bar-side");
        var width = 1;
        idInterval1 = setInterval(frame, 60);

        function frame() {
            if (width >= widthSuccess) {
                clearInterval(idInterval1);
                i = 0;
            } else {
                width+=0.2;
                elem.css("width", width + "%");
            }
        }
    }
    let timeout = 30;
    var j = 0;
    if (j == 0) {
        j = 1;
        var elem1 = $(".progress-convertion > p span");
         idInterval2 = setInterval(frame, 1000);

        function frame() {
            if (timeout === 0) {
                clearInterval(idInterval2);
                j = 0;
            } else {
                timeout-=1;
                if(timeout <= 9)elem1.html("0:0" + timeout);
                else elem1.html("0:" + timeout);
            }
        }
    }
}
$(document).ready(function(){
    HeaderDrop();
    TabsFaQ()
    Accordion();
    InitSlider();
    DropDrop();
    SelectChooseFiles();
    StartSelect();
    deleteFileItem();
    StartThreeStep();
    TabsPlan();
    changeInput();
    CloseBtnThreeStep();
    submitContactForm();

});